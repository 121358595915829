<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card
            class="mt-5"
            title="Password Reset"
          >
            <b-form @reset="onReset" @submit="onSubmit" v-if="show">
              <b-row>
                <!--Email-->
                <b-col md="12" sm="12">
                  <b-form-group
                    id="email"
                    :label="$t('Email:')"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      :placeholder="$t('Enter email')"
                      type="email"
                      v-model="email"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class=" form-text text-danger" v-if="!$v.email.required">{{$t('Email is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.email.email">{{$t('Email should a valid format ex. example@mail.com')}}</small>
                  </div>
                  <small class="form-text text-muted" id="emailHelp">
                    <!--                    TODO: https://learndjango.com/tutorials/django-password-reset-tutorial -->
                  </small>
                </b-col>
              </b-row>

              <div v-if="error">
                <b-alert show variant="info">
                  {{error}}
                </b-alert>
              </div>

              <div v-if="success">
                <b-alert show variant="success">
                  {{success}}
                </b-alert>
              </div>

              <div class="mt-3">
                <b-button class="mr-3" type="submit" variant="primary">{{$t('Send Password Reset Link')}}</b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { AuthService } from '../../services/auth.service'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import GenderAppFooter from './../../components/Footer'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      show: true,
      error: '',
      success: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = 'Please check above error(s).'
      } else {
        const data = {
          email: this.email
        }
        AuthService.sendPasswordResetEmail(data)
          .then((response) => {
            if (response.data.status_message) {
              this.error = response.data.status_message
            }
          })
          .catch((error) => {
            this.error = error
            this.success = ''
          })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.email = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  components: { TopNavBar, GenderAppFooter }
}
</script>

<style scoped>

</style>
